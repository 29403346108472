import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MapView from '../views/MapView.vue'
import MapView2 from '../views/MapView2.vue'
import MapView3 from '../views/MapView3.vue'
import MapView4 from '../views/MapView4.vue'
import DonateView from '../views/DonateView.vue'
import SubjectView from '../views/SubjectView.vue'
import MapCompareView from '../views/MapCompareView.vue'
import DemoTangView from '../views/DemoTangView.vue'
import DemoOttomanView from '../views/DemoOttomanView.vue'
import DemoAmericanView from '../views/DemoAmericanView.vue'
import DemoSilkRoadView from '../views/DemoSilkRoadView.vue'
import LoginView from '../views/LoginView.vue'
import MapPointEdit from '../views/MapPointEdit.vue'
import GraphView from '../views/GraphView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { pageTitle: 'home' },
    component: HomeView
  },
  {
    path: '/map',
    name: 'map',
    meta: { pageTitle: 'map' },
    component: MapView
  },
  {
    path: '/map2',
    name: 'map2',
    meta: { pageTitle: 'map2' },
    component: MapView2
  },
  {
    path: '/map3',
    name: 'map3',
    meta: { pageTitle: 'map3' },
    component: MapView3
  },
  {
    path: '/map4',
    name: 'map4',
    meta: { pageTitle: 'map3' },
    component: MapView4
  },
  {
    path: '/graph',
    name: 'graph',
    meta: { pageTitle: 'graph' },
    component: GraphView
  },
  {
    path: '/map-cmp',
    name: 'map-cmp',
    meta: { pageTitle: 'tool' },
    component: MapCompareView
  },
  {
    path: '/donate',
    name: 'donate',
    meta: { pageTitle: 'donate' },
    component: DonateView
  },
  {
    path: '/subject',
    name: 'subject',
    meta: { pageTitle: 'subject' },
    component: SubjectView
  },
  {
    path: '/tang',
    name: 'tang',
    meta: { pageTitle: 'tang' },
    component: DemoTangView
  },
  {
    path: '/ottoman',
    name: 'ottoman',
    meta: { pageTitle: 'ottoman' },
    component: DemoOttomanView
  },
  {
    path: '/usa',
    name: 'usa',
    meta: { pageTitle: 'usa' },
    component: DemoAmericanView
  },
  {
    path: '/silk',
    name: 'silk',
    meta: { pageTitle: 'silk' },
    component: DemoSilkRoadView
  },
  {
    path: '/login',
    name: 'login',
    meta: { pageTitle: 'login' },
    component: LoginView
  },
  {
    path: '/zhuchi-1A6787A263D53BF4D3B1157B9EA012C3',
    name: 'edit',
    meta: { pageTitle: 'edit' },
    component: MapPointEdit
  },
  {
    path: '/about',
    name: 'about',
    meta: { pageTitle: 'about' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/user-map-edit/:mid',
    name: 'user-map-edit',
    meta: { pageTitle: 'user-map-edit' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserMapEdit.vue')
  },
  {
    path: '/user-map-preview/:mid',
    name: 'user-map-preview',
    meta: { pageTitle: 'user-map-preview' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserMapPreview.vue')
  },
  {
    path: '/user-map-share/:mid',
    name: 'user-map-share',
    meta: { pageTitle: 'user-map-share' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserMapShare.vue')
  },
  {
    path: '/user',
    name: 'user',
    meta: { pageTitle: 'user' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserView.vue'),
    children: [
      {
        path: 'user-map',
        name: 'user-map',
        meta: { pageTitle: 'user-map' },
        component: () => import(/* webpackChunkName: "about" */ '../views/UserMap.vue')
      },
      {
        path: 'user-profile',
        name: 'user-profile',
        meta: { pageTitle: 'user-profile' },
        component: () => import(/* webpackChunkName: "about" */ '../views/UserProfile.vue')
      },
      {
        path: 'user-content',
        name: 'user-content',
        meta: { pageTitle: 'user-content' },
        component: () => import(/* webpackChunkName: "about" */ '../views/UserContent.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

export default router
